// Function to update the URL with query parameters dynamically
const updateFilterHistory = (
  locationFilters,
  path,
  navigate,
  currentState = {}
) => {
  const queryParams = new URLSearchParams();
  console.log("locationFilters", locationFilters);
  Object.keys(locationFilters).forEach((key) => {
    const value = locationFilters[key];
    if (value !== undefined && value !== null) {
      if (value instanceof Date) {
        queryParams.set(key, value.toISOString());
      } else if (typeof value === "object") {
        queryParams.set(key, encodeURIComponent(JSON.stringify(value)));
      } else {
        queryParams.set(key, value);
      }
    } else {
      queryParams.set(key, "");
    }
  });

  // console.log(`?${queryParams.toString()}`, queryParams.toString());
  navigate(`/${path}?${queryParams.toString()}`, {
    state: currentState,
  });
};

// Function to parse query parameters from URL and update state dynamically
const getFilterHistory = (location, callback) => {
  const queryParams = new URLSearchParams(location.search);

  // Dynamically create variables to hold parameter values
  const paramObj = {};
  const paramValues = []; // Store parameter values in order
  queryParams.forEach((value, key) => {
    try {
      const decodedValue = decodeURIComponent(value);
      // Check if the key is startDate or endDate and convert to Date object
      if (key === "startDate" || key === "endDate") {
        console.log(decodedValue);
        if (decodedValue) {
          paramObj[key] = new Date(decodedValue);
        } else {
          paramObj[key] = decodedValue;
        }
      } else {
        paramObj[key] = JSON.parse(decodedValue);
      }
    } catch (e) {
      // If parsing fails, use the original value
      const decodedValue = decodeURIComponent(value);
      if (key === "page" && decodedValue === "") {
        paramObj[key] = 1;
      } else if (
        (key === "perPage" || key === "limit") &&
        decodedValue === ""
      ) {
        paramObj[key] = 10;
      } else {
        paramObj[key] = decodedValue;
      }
    }
    paramValues.push(paramObj[key]); // Store parameter value in order
  });
  console.log("paramValues", paramValues);
  // if (paramValues || paramObj) {
  //   // Dynamically update the state based on the parsed parameters
  //   Object.keys(setFunctions).forEach((key) => {
  //     if (setFunctions[key] && typeof setFunctions[key] === "function") {
  //       setFunctions[key](paramObj[key] || "");
  //     }
  //   });
  // }

  // Call the callback function with each parameter individually
  if (callback && typeof callback === "function") {
    callback(...paramValues); // Pass parameter values in order
  }
};

export { updateFilterHistory, getFilterHistory };

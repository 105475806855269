import React, { useRef, useState, useEffect } from "react";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PostEditor = ({
  editorState,
  onChangeEditor = () => {},
  placeholder = "",
  isReady = false,
}) => {
  const editorReference = useRef(null);
  const extractMentions = (rawContent) => {
    let mentionedUsers = [];
    for (let key in rawContent.entityMap) {
      const ent = rawContent.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention);
      }
    }
    return mentionedUsers;
  };

  const replaceMentionsWithLinks = (html, mentionedUsers) => {
    return html.replace(/<p>(.*?)<\/p>/g, (match, content) => {
      let replacedContent = content;
      mentionedUsers.forEach((mention) => {
        const mentionRegex = new RegExp(mention.name, "g");
        replacedContent = replacedContent.replace(
          mentionRegex,
          `<a href="/profile/${mention.username}">${mention.name}</a>`
        );
      });
      return `<p>${replacedContent}</p>`;
    });
  };

  const extractHashtags = (text) => {
    return [...new Set(text.match(/#\w+/g) || [])];
  };

  const replaceHashtagsWithLinks = (html) => {
    return html.replace(
      /<p>([^<]*#[^\s#]+[^<]*)<\/p>/g,
      (match, content) =>
        `<p>${content.replace(
          /#([^\s#]+)/g,
          '<a href="/trending-posts?tag=$1">#$1</a>'
        )}</p>`
    );
  };

  const onChangeEditorHandle = (editorState) => {
    const content = editorState.getCurrentContent();
    const plainText = content.getPlainText("");
    const rawContent = convertToRaw(content);
    const mentionedUsers = extractMentions(rawContent);
    const hashtags = extractHashtags(plainText);
    const html = draftToHtml(rawContent);
    const htmlWithMentionLinks = replaceMentionsWithLinks(html, mentionedUsers);
    const htmlWithHashtagLinks = replaceHashtagsWithLinks(htmlWithMentionLinks);
    onChangeEditor(editorState, htmlWithHashtagLinks, hashtags);
  };

  const handlePastedText = (text, html, editorState) => {
    // Handle plain text paste without styles
    const newContent = editorState.getCurrentContent();
    const newState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    onChangeEditorHandle(newState);
    return newState;
  };

  const handleCustomPaste = async () => {
    try {
      const clipboardData = await navigator.clipboard.readText();
      const pastedContentState = ContentState.createFromText(clipboardData);
      const newState = EditorState.push(
        editorState,
        pastedContentState,
        "insert-characters"
      );
      onChangeEditorHandle(newState);
    } catch (error) {
      console.error("Error pasting content:", error);
    }
  };
  const wordCount = () => {
    const content = editorState.getCurrentContent();
    const plainText = content.getPlainText("");
    const wordArray = plainText.split(/\s+/).filter(Boolean);

    return wordArray?.length > 0 ? wordArray?.length : 0;
  };
  useEffect(() => {
    if (editorReference.current) {
      editorReference.current.focusEditor();
    }
  }, []);

  return (
    <div>
      <div
        onClick={() => {
          if (editorReference.current) {
            editorReference.current.focusEditor();
          }
        }}
      >
        <div className="richEditor mb-2">
          {console.log(
            "editorState",
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
          )}
          <Editor
            editorState={editorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={onChangeEditorHandle}
            ref={editorReference}
            hashtag={{
              separator: " ",
              trigger: "#",
            }}
            toolbarCustomButtons={[
              <button
                type="button"
                aria-label="PASTE-BUTTON"
                className="btn"
                onClick={handleCustomPaste}
              >
                <FontAwesomeIcon icon={faPaste} /> Paste
              </button>,
            ]}
            placeholder={placeholder}
            handlePastedText={handlePastedText}
            spellCheck={false}
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            toolbar={{
              options: ["inline", "history"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              history: {
                options: ["undo", "redo"],
              },
              customPaste: {
                icon: "Paste", // You can customize the icon
                onClick: handleCustomPaste,
              },
            }}
          />
        </div>
        <div style={{ paddingLeft: "1rem" }}>
          Word Count: {wordCount(editorState)}
        </div>
      </div>
    </div>
  );
};

export default PostEditor;

import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../helper/cropImage";

import { Modal, Button, Row, Col } from "react-bootstrap";
const FileUpload = ({
  image,
  setCroppedImage,
  handleCloseModal,
  handleSaveAvatar,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const finalizeCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
    handleSaveAvatar();
  };
  return (
    <>
      <Modal.Body
        style={{
          height: 450,
        }}
      >
        <div className="reactImageCroper">
          <div className="crop-container">
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropSize={{
                width: 87,
                height: 87,
              }}
              showGrid={false}
              cropShape="round"
            />
          </div>
          <div className="controls_cover">
            <div className="controls">
              <label>Zoom</label>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
            </div>
            <div className="controls">
              <label>Rotation</label>
              <input
                type="range"
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="rotation"
                onChange={(e) => {
                  setRotation(e.target.value);
                }}
                className="zoom-range"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 flex-column-reverse flex-md-row">
          <Col lg={6} md={6} xs={12}>
            <Button
              className="btn-default-3 w-100 d-block h-auto px-3 py-3"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={6} md={6} xs={12} className="mb-3 m-md-0 m-lg-0">
            <Button
              className="btn-default-2 w-100 d-block h-auto px-3 py-3"
              onClick={finalizeCroppedImage}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </>
  );
};

export default FileUpload;

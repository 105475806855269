import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PublicImages from "../../constants/public/images";
import Layout from "../../layouts/public/Layout";
import Share from "../../components/public/PopUps/Share";
import { getRequest, postRequestForm, uploadURL } from "../../helper/api";
import Loader from "../../components/Loader";
import UploadVideo from "./profile/UploadVideo";
import UploadAudio from "./profile/UploadAudio";
import UploadImage from "./profile/UploadImage";
import UploadStory from "./profile/UploadStory";
import UploadBlog from "./profile/UploadBlog";
import Bookmarks from "./profile/Bookmarks";
import Drafts from "./profile/Drafts";
import { getItemFromLocalStorage } from "../../helper/helper";
import { toast } from "react-toastify";
import { useMemo } from "react";
import UserBadges from "../../components/public/UserBadges";

export default function UserProfile() {
  const { username } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const accountTab = queryParams.get("accountTab");
  const [loading, setLoading] = useState(true);
  const [shareActive, setShareActive] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [postCount, setPostCount] = useState(0);
  const [badges, setBadges] = useState(0);
  const [followers, setFollowers] = useState(0);
  const [followings, setFollowings] = useState(0);
  const [activeTab, setActiveTab] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [user, setUser] = useState(null);
  const [IsOwned, setIsOwned] = useState(false);
  const [following, setFollowing] = useState({});
  const [option, setOption] = useState([
    // {
    //   activeImage: PublicImages.activeList,
    //   image: PublicImages.Group83,
    //   title: "Drafts",
    //   Component: Drafts,
    //   isProtected: "yes",
    // },
    {
      activeImage: PublicImages.activeSave,
      mobileActiveImage: PublicImages.BookmarkActive,
      image: PublicImages.BookmarkIcon,
      mobileImage: PublicImages.BookmarkIcon,
      title: "Bookmarks",
      Component: Bookmarks,
      isProtected: "yes",
    },
    {
      activeImage: PublicImages.activeList,
      mobileActiveImage: PublicImages.activeList,
      image: PublicImages.Group83,
      mobileImage: PublicImages.Group83,
      title: "Blogs",
      Component: UploadBlog,
      isProtected: "no",
    },
    {
      activeImage: PublicImages.activeEditNote,
      mobileActiveImage: PublicImages.activeEditNote,
      image: PublicImages.edit_note,
      mobileImage: PublicImages.edit_note,
      title: "Short Stories",
      Component: UploadStory,
      isProtected: "no",
    },
    {
      activeImage: PublicImages.activeImage,
      mobileActiveImage: PublicImages.activeImage,
      image: PublicImages.Group84,
      mobileImage: PublicImages.Group84,
      title: "Images",
      Component: UploadImage,
      isProtected: "no",
    },
    {
      activeImage: PublicImages.activeMic,
      mobileActiveImage: PublicImages.activeMic,
      image: PublicImages.mic,
      mobileImage: PublicImages.mic,
      title: "Audios",
      Component: UploadAudio,
      isProtected: "no",
    },
    {
      activeImage: PublicImages.activeVideo,
      mobileActiveImage: PublicImages.activeVideo,
      image: PublicImages.Isolation_Mode_1,
      mobileImage: PublicImages.Isolation_Mode_1,
      title: "Videos",
      Component: UploadVideo,
      isProtected: "no",
    },
  ]);
  const getProfile = async (username) => {
    try {
      setLoading(true);
      const { result, error } = await getRequest(
        `/api/secure/user/profile/${username}`
      );
      if (result?.status === 200 || result?.status === 201) {
        const {
          _id,
          profileImage,
          referralCode,
          firstName,
          lastName,
          postCount,
          awardedBadges,
        } = result?.data?.user;
        console.log("result?.data?.user", result?.data?.user);
        setUser(result?.data?.user);
        const { followers, following } = result?.data?.userPrefrences;
        setReferralCode(referralCode);
        setFirstName(firstName);
        setLastName(lastName !== undefined ? lastName : "");
        setPostCount(postCount);
        console.log(profileImage, referralCode, firstName, lastName);
        setBadges(awardedBadges ? awardedBadges.length : 0);
        setFollowers(followers ? followers.length : 0);
        setFollowings(following ? following.length : 0);
        if (profileImage != undefined || profileImage || profileImage != null) {
          setProfileImage(uploadURL + profileImage);
        } else {
          setProfileImage(null);
        }
        const loggedInUser = getItemFromLocalStorage("USER");
        if (loggedInUser) {
          if (_id === JSON.parse(loggedInUser)?._id) {
            setIsOwned(true);
          }
        } else {
          setIsOwned(false);
        }
      } else if (error?.response?.status === 400) {
      }
    } catch (err) {
      console.log(`Error of Get Profile by Username`, err.message);
    } finally {
      setLoading(false);
    }
  };

  const followUserHandler = async (userId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/follow-user`,
        token,
        {
          userIdToFollow: userId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setFollowing((prev) => {
          const newState = { ...prev };
          newState[userId] = true;
          toast.success("User has been added to Followings!", {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const unfollowUserHandler = async (userId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/unfollow-user`,
        token,
        {
          userIdToUnfollow: userId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setFollowing((prev) => {
          const newState = { ...prev };

          if (newState[userId]) {
            delete newState[userId];
          }
          toast.error("User has been removed from Followings!", {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const formatString = (input) => {
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  useEffect(() => {
    if (username) {
      getProfile(username);
      if (accountTab) {
        setActiveTab(accountTab);
      } else {
        console.log("IsOwned", IsOwned);
        if (!IsOwned) {
          setActiveTab("Blogs");
        } else {
          setActiveTab("Bookmarks");
        }
      }
    } else {
      navigate("/");
    }
    console.log("activeTab", activeTab);
  }, [accountTab, username, IsOwned]);
  const optionsMemo = useMemo(() => {
    return async () => {
      try {
        setLoading(true);

        // Static options for "Drafts" and "Bookmarks"
        const staticOptions = [
          // {
          //   activeImage: PublicImages.activeList,
          //   image: PublicImages.Group83,
          //   title: "Drafts",
          //   Component: Drafts,
          //   isProtected: "yes",
          // },
          {
            activeImage: PublicImages.activeSave,
            mobileActiveImage: PublicImages.BookmarkActive,
            image: PublicImages.bookmark_add,
            mobileImage: PublicImages.BookmarkIcon,
            title: "Bookmarks",
            Component: Bookmarks,
            isProtected: "yes",
          },
        ];

        const token = await getItemFromLocalStorage("TOKEN");
        const response = await getRequest(
          `/api/secure/product/get-all-products`,
          token,
          {
            status: "active",
          }
        );

        // Map products to the format expected by the option state
        const mappedOptions = response?.result?.data?.products.map(
          ({ name, featuredImage }) => {
            let component;
            if (formatString(name)?.includes("Blogs")) {
              component = UploadBlog;
            } else if (formatString(name)?.includes("Stories")) {
              component = UploadStory;
            } else if (formatString(name)?.includes("Image")) {
              component = UploadImage;
            } else if (formatString(name)?.includes("Video")) {
              component = UploadVideo;
            } else if (formatString(name)?.includes("Audio")) {
              component = UploadAudio;
            } else {
              component = null; // Default component if no match
            }

            return {
              activeImage: `${uploadURL}${featuredImage}`, // Assuming you have an uploadURL variable defined
              mobileActiveImage: `${uploadURL}${featuredImage}`, // Assuming you have an uploadURL variable defined
              image: `${uploadURL}${featuredImage}`,
              mobileImage: `${uploadURL}${featuredImage}`,
              title: name,
              Component: component,
              isProtected: "no",
            };
          }
        );

        // Concatenate static options with dynamic options
        const finalOptions = staticOptions.concat(mappedOptions);
        return finalOptions;
      } catch (error) {
        console.log("Get All Products Error", error);
      } finally {
        setLoading(false);
      }
    };
  }, []); // Empty dependency array to ensure it runs only once
  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { userPrefrences } = result?.data;
            const { following } = userPrefrences;
            setFollowing((prev) => ({
              ...prev,
              ...following.reduce(
                (acc, userId) => ({ ...acc, [userId]: true }),
                {}
              ),
            }));
          }
        } else {
          setFollowing({});
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setFollowing({});
      }
    };
  }, []);
  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);
  useEffect(() => {
    // Set the option state using the memoized options
    optionsMemo().then((result) => {
      setOption(result);
    });
  }, [optionsMemo]);
  return (
    <Layout>
      <section className="userProfileSection">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Container>
            {user ? (
              <>
                <section id="listing_main">
                  <div className="listing_sec border-0">
                    <div className="bg-light px-0 px-md-5">
                      <div className="d-flex pb-0 flex-column flex-md-row justify-content-between align-items-center">
                        <div className="d-flex flex-column p-0">
                          <div className="d-flex flex-1 px-0 gap-3 pt-0 px-md-auto">
                            <div className="img profileImage">
                              {profileImage ? (
                                <Image
                                  src={profileImage}
                                  fluid
                                  alt=""
                                  width={72}
                                  height={72}
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                  }}
                                />
                              ) : (
                                <svg
                                  width="72px"
                                  height="72px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  id="Layer_1"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 224.2 319.9"
                                >
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M48.1,107.5c-4.9-1.5-7.2-6.3-5-10c0.6-1,2.8-1.5,4.2-1.5c14.8-0.1,29.7-0.1,44.5-0.1  c15.7,0,22.3-5.1,26.5-20.1c3-10.7,5.9-21.5,9.4-32.1c0.8-2.4,3.3-5.4,5.6-5.9c3.8-0.9,5.2,2.8,6.1,5.9c3.6,12.5,6.9,25,10.6,37.5  c3.3,11.2,8.2,14.7,20,14.8c1.7,0,3.3,0,5,0c6.3,0.1,8.3,2.5,6.3,8.5c-0.5,1.4-2.8,2.2-5.1,4c0,6.4-0.1,13.8,0,21.3  c0.4,24.1-9.1,43.2-29,57c-1.6,1.1-2.7,3.7-2.8,5.7c-0.3,7.1,0,14.3-0.2,21.5c-0.1,3.7,1,4.9,4.8,4.8c11.8-0.2,23.7-0.1,35.5-0.1  c24,0.1,39.7,15.8,39.8,39.8c0.1,17.5,0,35,0,52.5c0,7.5-1.5,9-9.1,9c-68.6,0-137.3,0-205.9,0c-7.8,0-9.1-1.2-9.1-8.9  c0-17.7-0.1-35.3,0-53c0.2-20.6,13-36.6,31.6-38.8c8.2-1,16.6-0.5,25-0.5c6.5-0.1,13-0.1,19.5,0c2.8,0.1,3.9-1,3.8-3.8  c-0.1-7.8,0.1-15.7-0.2-23.5c-0.1-1.6-1.4-3.8-2.8-4.7c-20.2-14-29.4-33.3-28.9-57.6C48.2,122,48.1,114.8,48.1,107.5L48.1,107.5z   M212.6,308.9c0.3-0.7,0.6-1.2,0.6-1.6c0-18.2,0.4-36.3-0.2-54.5c-0.4-11.8-10.7-22.2-22.7-22.9c-15.6-0.8-31.3-0.6-46.9-0.5  c-1.7,0-3.7,1.4-5,2.6c-6.9,6.7-13.7,13.6-20.5,20.5c-4.6,4.5-6.8,4.5-11.3,0.1c-6.9-6.8-13.6-13.7-20.6-20.4  c-1.5-1.5-3.9-2.8-5.9-2.8c-13.8-0.2-27.7-0.4-41.5,0C21,229.9,11,240.6,10.9,258.1c-0.1,15.2,0,30.3,0,45.5c0,1.8,0.2,3.5,0.3,5.3  L212.6,308.9L212.6,308.9z M134,64.7l-1.3,0.2c-1.9,6.5-3.6,13.1-5.7,19.5c-3.9,12.4-14.6,21.1-27.7,21.7c-11.5,0.6-23,0.5-34.4,0.2  c-4.7-0.1-6.7,1.1-6.3,6.1c0.4,6,0.2,12,0.2,18c-0.2,30.8,20.3,53.3,50.9,55.7c22.7,1.8,46-14.4,52.8-36.2  c4.2-13.4,2.4-27.1,2.9-40.6c0-1-1.4-2.9-2.4-3.1c-14-2.1-20.9-11.1-23.9-24C137.6,76.3,135.7,70.6,134,64.7L134,64.7z M112.1,243.6  c7.1-7.3,13.4-13.7,19.6-20.2c0.7-0.7,1.4-1.7,1.4-2.5c0.1-8.9,0.1-17.8,0.1-27.3c-14.4,4.8-28.1,4.5-42.3,0.1c0,9.3,0,18,0.1,26.6  c0,0.9,0.4,2,1.1,2.7C98.4,229.5,104.9,236.2,112.1,243.6z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M192,212.6c0-10.7,0-21.2,0-31.7c0-29.5,0-59,0-88.5c0.1-41.8-28.1-75.3-69.5-81C77.4,5.3,38.8,37,33.5,77.9  c-2.4,18.7-1.3,37.9-1.4,56.9c-0.2,24.3-0.1,48.6,0.1,73c0,4.3-1,5.7-5.5,5.8c-4.7,0-5.4-1.9-5.4-6c0.1-38.3,0-76.6,0.1-114.9  c0.1-41,24.7-75.8,62.2-88C134.8-12,188.7,18,200.2,70.6c3,13.6,2.4,28.1,2.5,42.2c0.3,30.6,0.1,61.3,0,92  C202.8,214.2,202.5,214.4,192,212.6L192,212.6z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M131.8,155.1c-0.5,11.2-9.2,19.6-19.7,19.5c-10.7,0-19-8.1-19.8-19.4c4.1-0.9,7.6-1.9,9.3,4.1  c1.3,4.6,5.6,6.9,10.5,6.9s9.3-2.1,10.5-6.8C124.1,153.7,127.5,154.4,131.8,155.1L131.8,155.1z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M85.5,118.7c5,0,9.2,4.2,9.1,9.3c-0.1,5-4.3,9.1-9.3,9.1c-5,0-9.2-4.3-9.1-9.3  C76.2,122.8,80.5,118.7,85.5,118.7L85.5,118.7z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M148,127.7c0.1,4.9-4.1,9.2-9.1,9.3c-5.1,0.1-9.2-3.9-9.3-9c-0.1-5.1,3.9-9.2,9-9.3  C143.7,118.7,147.9,122.7,148,127.7L148,127.7z"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="align-self-center">
                              <h1 className="mb-0 p-0 text-start lh-base">
                                {firstName + " " + lastName}
                              </h1>
                            </div>
                          </div>
                          {<UserBadges badges={user?.awardedBadges} />}
                        </div>
                        <div className="d-flex px-0 px-md-auto flex-1 justify-content-center justify-content-md-end">
                          {IsOwned ? (
                            <Button
                              className="btn-default rounded-pill me-3"
                              onClick={() => navigate("/account")}
                            >
                              <i className="far fa-pencil"></i> EDIT
                            </Button>
                          ) : following[user?._id] ? (
                            <Button
                              className="btn-default-3 h-auto w-auto btn-sm rounded-pill me-3 text-uppercase"
                              onClick={() => {
                                unfollowUserHandler(user?._id);
                              }}
                              style={{
                                padding: "10px 30px",
                              }}
                            >
                              Followings
                            </Button>
                          ) : (
                            <Button
                              className="btn-default rounded-pill me-3"
                              onClick={() => followUserHandler(user?._id)}
                            >
                              FOLLOW
                            </Button>
                          )}
                          <Button
                            className="btn-default rounded-pill d-inline-flex align-items-center gap-2"
                            onClick={() => {
                              setShareActive(true);
                            }}
                          >
                            <i className="far fa-share-alt"></i> SHARE
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex bullet flex-wrap flex-md-nowrap">
                        <div className="col-lg-2 col-md-2 col-6">
                          Posts{" "}
                          <a href="#">
                            <b>{postCount}</b>
                          </a>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6">
                          Badges{" "}
                          <a href="#">
                            <b>{badges}</b>
                          </a>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6">
                          Followers{" "}
                          <a href="#">
                            <b>{followers}</b>
                          </a>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6">
                          Following{" "}
                          <a href="#">
                            <b>{followings}</b>
                          </a>
                        </div>
                      </div>
                    </div>
                    {console.log("option", option)}
                    <Tab.Container
                      activeKey={activeTab}
                      onSelect={(e) => {
                        setActiveTab(e);
                        console.log("e", e);
                      }}
                    >
                      <div className="pt-5">
                        <Nav
                          role="tablist"
                          className="nav-tabs  d-none d-md-flex"
                          as="ul"
                        >
                          {option.map(
                            (
                              { title, activeImage, image, isProtected },
                              index
                            ) => {
                              if (IsOwned) {
                                return (
                                  <Nav.Item
                                    as="li"
                                    key={index}
                                    className={
                                      title === activeTab ? "active" : ""
                                    }
                                  >
                                    <Nav.Link
                                      className="btn border-0 m-0 bg-transparent"
                                      as={Link}
                                      eventKey={title}
                                      title={title}
                                      to={`/profile/${username}?accountTab=${title}`}
                                    >
                                      <Image
                                        src={
                                          title === activeTab
                                            ? activeImage
                                            : image
                                        }
                                        fluid
                                        alt=""
                                      />
                                    </Nav.Link>
                                  </Nav.Item>
                                );
                              } else if (!IsOwned && isProtected !== "yes") {
                                return (
                                  <Nav.Item
                                    as="li"
                                    key={index}
                                    className={
                                      title === activeTab ? "active" : ""
                                    }
                                  >
                                    <Nav.Link
                                      className="btn border-0 m-0 bg-transparent"
                                      as={Link}
                                      eventKey={title}
                                      title={title}
                                      to={`/profile/${username}?accountTab=${title}`}
                                    >
                                      <Image
                                        src={
                                          title === activeTab
                                            ? activeImage
                                            : image
                                        }
                                        fluid
                                        alt=""
                                      />
                                    </Nav.Link>
                                  </Nav.Item>
                                );
                              }
                            }
                          )}
                        </Nav>
                        <Row className="mobileProfileMenu d-md-none">
                          {option.map(
                            (
                              {
                                title,
                                mobileActiveImage,
                                mobileImage,
                                isProtected,
                              },
                              index
                            ) => {
                              if (IsOwned) {
                                return (
                                  <Col
                                    xs={
                                      (option.length - 1) % 2 === 0 &&
                                      index === option.length - 1
                                        ? 12
                                        : 6
                                    }
                                    key={index}
                                  >
                                    <Card
                                      className={
                                        title === activeTab ? "active" : ""
                                      }
                                    >
                                      <Card.Body>
                                        <div className="card-logo">
                                          <div className="card-svg">
                                            <Nav.Link
                                              className="btn border-0 m-0"
                                              as={Link}
                                              eventKey={title}
                                              title={title}
                                              to={`/profile/${username}?accountTab=${title}`}
                                            >
                                              <Image
                                                src={
                                                  title === activeTab
                                                    ? mobileActiveImage
                                                    : mobileImage
                                                }
                                                fluid
                                                alt=""
                                              />
                                            </Nav.Link>
                                          </div>
                                        </div>
                                        <div className="card-title">
                                          <Nav.Link
                                            className="btn border-0 m-0"
                                            as={Link}
                                            eventKey={title}
                                            title={title}
                                            to={`/profile/${username}?accountTab=${title}`}
                                          >
                                            {title}
                                          </Nav.Link>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                );
                              } else if (!IsOwned && isProtected !== "yes") {
                                return (
                                  <Col
                                    xs={
                                      (option.length - 1) % 2 === 0 &&
                                      index === option.length - 1
                                        ? 12
                                        : 6
                                    }
                                    key={index}
                                  >
                                    <Card>
                                      <Card.Body>
                                        <div className="card-logo">
                                          <div className="card-svg">
                                            <Nav.Link
                                              className="btn border-0 m-0"
                                              as={Link}
                                              eventKey={title}
                                              title={title}
                                              to={`/profile/${username}?accountTab=${title}`}
                                            >
                                              <Image
                                                src={
                                                  title === activeTab
                                                    ? mobileActiveImage
                                                    : mobileImage
                                                }
                                                fluid
                                                alt=""
                                              />
                                            </Nav.Link>
                                          </div>
                                        </div>
                                        <div className="card-title">
                                          <Nav.Link
                                            className="btn border-0 m-0"
                                            as={Link}
                                            eventKey={title}
                                            title={title}
                                            to={`/profile/${username}?accountTab=${title}`}
                                          >
                                            {title}
                                          </Nav.Link>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                );
                              }
                            }
                          )}
                        </Row>
                        <Tab.Content className="tab-content">
                          {option.map(
                            ({ title, Component, isProtected }, index) => {
                              if (IsOwned) {
                                return (
                                  <Tab.Pane
                                    key={`${title}-${index}`}
                                    eventKey={title}
                                  >
                                    <Component
                                      currentlyActive={activeTab}
                                      actualComponent={title}
                                      user={user?._id}
                                    />
                                  </Tab.Pane>
                                );
                              } else if (!IsOwned && isProtected !== "yes") {
                                return (
                                  <Tab.Pane
                                    key={`${title}-${index}`}
                                    eventKey={title}
                                  >
                                    <Component
                                      currentlyActive={activeTab}
                                      actualComponent={title}
                                      user={user?._id}
                                    />
                                  </Tab.Pane>
                                );
                              }
                            }
                          )}
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </section>
                <Share
                  show={shareActive}
                  handleClose={() => {
                    setShareActive(false);
                  }}
                  referralCode={referralCode}
                  username={user?.username}
                  isReferral={true}
                />
              </>
            ) : (
              <p className="text-center">User Not Found!</p>
            )}
          </Container>
        )}
      </section>
    </Layout>
  );
}

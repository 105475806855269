import React, { useState } from "react";
import { Modal, Button, Row, Col, Image, Ratio } from "react-bootstrap";
import { uploadURL } from "../../../helper/api";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";

const PostPreview = ({ post, show, handleClose, onStarMark, onMakeWinner }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      scrollable
      backdrop="static"
      keyboard={false}
      id="menu-modal"
      size="md"
      backdropClassName="welcome-popup-backdrop"
    >
      <Modal.Header
        className="justify-content-center position-relative"
        style={{
          borderRadius: "25px 25px 0px 0px",
          background: "#E5097D",
        }}
      >
        <h5
          className="mb-0 postPreview"
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Editors-Note",
            fontSize: "36px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            textTransform: "uppercase",
          }}
        >
          {post.title}
        </h5>
        <button
          type="button"
          className="btn-close position-absolute"
          style={{
            right: "15px",
            top: "15px",
            filter: "invert(1)",
            opacity: "0.7",
          }}
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <Modal.Body>
        {post.featuredImage && !post.featuredFile && (
          <div controls className="w-100 mb-3">
            <Ratio aspectRatio="16x9" className="w-100 mb-3">
              <Image
                src={uploadURL + post.featuredImage}
                alt={post.title}
                fluid
                className="w-100 mb-3"
                style={{
                  objectFit: "contain",
                  backgroundColor: "#fff0f7",
                  backgroundBlendMode: "overlay",
                }}
              />
            </Ratio>
          </div>
        )}

        {post.featuredFile && post.featuredFile.endsWith(".mp4") && (
          <div controls className="w-100 mb-3">
            <Ratio aspectRatio="16x9">
              <ReactPlayer
                url={uploadURL + post.featuredFile}
                controls
                playing
                light={uploadURL + post.featuredImage}
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                // Disable right click
                onContextMenu={(e) => e.preventDefault()}
                width="100%"
                height="100%"
                showPreview={true}
              />
            </Ratio>
          </div>
        )}

        {post.featuredFile && post.featuredFile.endsWith(".mp3") && (
          <div controls className="w-100 mb-3">
            <Ratio aspectRatio="16x9" className="w-100 mb-3">
              <Image
                fluid
                src={uploadURL + post.featuredImage}
                className="w-100"
                alt={post.title}
                style={{
                  objectFit: "contain",
                  backgroundColor: "#fff0f7",
                  backgroundBlendMode: "overlay",
                }}
              />
            </Ratio>
            <AudioPlayer
              autoPlay={false}
              src={uploadURL + post.featuredFile}
              onPlay={(e) => console.log("onPlay")}
              // other props here
            />
          </div>
        )}

        <div dangerouslySetInnerHTML={{ __html: post.article }} />
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 flex-column-reverse flex-md-row">
          <Col lg={6} md={6} xs={12}>
            <Button
              className={`${
                post.isStarmarked ? "btn-default-3" : "btn-default-2"
              } w-100 d-block h-auto px-3 py-3`}
              onClick={() => onStarMark(post._id)}
            >
              {post.isStarmarked ? "Unmark Star" : "Star Mark"}
            </Button>
          </Col>
          <Col lg={6} md={6} xs={12} className="mb-3 m-md-0 m-lg-0">
            <Button
              className={`${
                post.isWinner ? "btn-default-3" : "btn-default-2"
              } w-100 d-block h-auto px-3 py-3`}
              onClick={() => onMakeWinner(post._id)}
            >
              {post.isWinner ? "Already Winner" : "Make Winner"}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default PostPreview;

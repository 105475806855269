import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getRequest, postRequestForm, uploadURL } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Loader from "../../Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Card, Col, Row, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const UserSearch = ({ search, tab }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [topUsers, setTopUsers] = useState([]);
  const [topUsersLength, setTopUserLength] = useState(0);
  const [topUsersLoading, setTopUsersLoading] = useState(true);
  const [topUsersError, setTopUsersError] = useState("");
  const [topUsersEnded, setTopUsersEnded] = useState(true);
  const [topUsersPage, setTopUsersPage] = useState(1);
  const [IsOwned, setIsOwned] = useState({});
  const [following, setFollowing] = useState({});
  const unfollowUserHandler = async (userId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/unfollow-user`,
        token,
        { userIdToUnfollow: userId }
      );

      if (result?.status === 200 || result?.status === 201) {
        setFollowing((prev) => {
          const newState = { ...prev };
          delete newState[userId];
          toast.error("User has been removed from Followings!", {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadTopUsers = async (search) => {
    try {
      // setTopUsersLoading(true);
      setTopUsersError("");
      // Check if the search term is not empty to forget pagination
      const forgetPagination = search.trim() !== "";
      const page = forgetPagination ? 1 : topUsersPage; // Reset page number if forgetPagination is true
      const { result, error } = await getRequest(
        `/api/public/search/users`,
        "",
        {
          search: search,
          page: page,
          limit: 10,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        if (topUsersPage === 1 || forgetPagination) {
          setTopUsers(result?.data.result);
          // Inside the function where you load more data
          setTopUserLength(result?.data.result.length);
        } else {
          setTopUsers((prevData) => [...prevData, ...result?.data.result]);
          // Inside the function where you load more data
          setTopUserLength(topUsers.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setTopUsersEnded(false);
        }
        if (!forgetPagination) {
          setTopUsersPage((prevPage) => {
            const newState = prevPage + 1;
            console.log(newState);
            return newState;
          });
        }
        const loggedInUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (loggedInUser) {
          setIsOwned((prev) => {
            let isBookmarked = false;
            const newState = { ...prev };
            result?.data.result.forEach((item) => {
              if (loggedInUser._id === item._id) {
                // If the user is the logged-in user, check ownership
                if (newState[item._id]) {
                  // If user is already owned, remove it
                  delete newState[item._id];
                } else {
                  // If user is not owned, add it
                  newState[item._id] = true;
                }
              }
            });
            return newState;
          });
        } else {
          setIsOwned({});
        }
      } else if (error?.response?.status === 400) {
        setTopUsersError(error?.response?.data?.message);
      }
    } catch (error) {
      setTopUsersError(error.message);
    } finally {
      setTopUsersLoading(false);
    }
  };

  const followUserHandler = async (userId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/follow-user`,
        token,
        { userIdToFollow: userId }
      );

      if (result?.status === 200 || result?.status === 201) {
        setFollowing((prev) => {
          const newState = { ...prev };
          newState[userId] = true;
          toast.success("User has been added to Followings!", {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedLoadTopUsers = useCallback(
    debounce((search) => {
      loadTopUsers(search);
    }, 500),
    []
  );

  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        setLoading(true);
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { userPrefrences } = result?.data;
            const { following } = userPrefrences;
            setFollowing((prev) => ({
              ...prev,
              ...following.reduce(
                (acc, userId) => ({ ...acc, [userId]: true }),
                {}
              ),
            }));
          }
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
      } finally {
        setLoading(false);
      }
    };
  }, []);

  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);

  useEffect(() => {
    if (tab === "users") {
      setLoading(false);
      setTopUsers([]);
      setTopUserLength(0);
      setTopUsersLoading(true);
      setTopUsersError("");
      setTopUsersEnded(true);
      setTopUsersPage(1);
      setIsOwned({});
      setFollowing({});
      debouncedLoadTopUsers(search);
    }
  }, [search, tab]);

  return (
    <Tab.Pane eventKey={"users"}>
      <section className="topContributorsSection pt-2 pt-md-4">
        {topUsersLoading ? (
          <Loader loading={true} />
        ) : (
          <div className="topContributorsList">
            {topUsersError ? (
              topUsersError
            ) : (
              <InfiniteScroll
                dataLength={topUsersLength}
                next={() => {
                  loadTopUsers(search);
                }}
                inverse={false}
                hasMore={topUsersEnded}
                loader={<Loader loading={true} />}
                scrollThreshold={0.9}
                endMessage={
                  <p
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      marginTop: "20px",
                    }}
                  >
                    <b>Yay! You've seen it all!</b>
                  </p>
                }
              >
                <Row className="g-0 justify-content-evenly">
                  {topUsers.map((item, index) => (
                    <Col lg={5} md={12} xs={12} className="mb-3">
                      <Card>
                        <Card.Body>
                          <div className="d-flex align-items-start align-items-sm-center justify-content-between gap-3 flex-column flex-sm-row">
                            <div className="d-flex align-items-start align-items-sm-center justify-content-between gap-3">
                              {item?.profileImage ? (
                                <Link
                                  to={`/profile/${item.username}`}
                                  reloadDocument={true}
                                >
                                  <img
                                    src={uploadURL + item.profileImage}
                                    className="img-fluid"
                                    alt="..."
                                    width="85"
                                    height="85"
                                    style={{
                                      height: "85px",
                                      width: "85px",
                                    }}
                                  />
                                </Link>
                              ) : (
                                <Link
                                  to={`/profile/${item.username}`}
                                  reloadDocument={true}
                                >
                                  <svg
                                    width="87px"
                                    height="87px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    id="Layer_1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 224.2 319.9"
                                  >
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M48.1,107.5c-4.9-1.5-7.2-6.3-5-10c0.6-1,2.8-1.5,4.2-1.5c14.8-0.1,29.7-0.1,44.5-0.1  c15.7,0,22.3-5.1,26.5-20.1c3-10.7,5.9-21.5,9.4-32.1c0.8-2.4,3.3-5.4,5.6-5.9c3.8-0.9,5.2,2.8,6.1,5.9c3.6,12.5,6.9,25,10.6,37.5  c3.3,11.2,8.2,14.7,20,14.8c1.7,0,3.3,0,5,0c6.3,0.1,8.3,2.5,6.3,8.5c-0.5,1.4-2.8,2.2-5.1,4c0,6.4-0.1,13.8,0,21.3  c0.4,24.1-9.1,43.2-29,57c-1.6,1.1-2.7,3.7-2.8,5.7c-0.3,7.1,0,14.3-0.2,21.5c-0.1,3.7,1,4.9,4.8,4.8c11.8-0.2,23.7-0.1,35.5-0.1  c24,0.1,39.7,15.8,39.8,39.8c0.1,17.5,0,35,0,52.5c0,7.5-1.5,9-9.1,9c-68.6,0-137.3,0-205.9,0c-7.8,0-9.1-1.2-9.1-8.9  c0-17.7-0.1-35.3,0-53c0.2-20.6,13-36.6,31.6-38.8c8.2-1,16.6-0.5,25-0.5c6.5-0.1,13-0.1,19.5,0c2.8,0.1,3.9-1,3.8-3.8  c-0.1-7.8,0.1-15.7-0.2-23.5c-0.1-1.6-1.4-3.8-2.8-4.7c-20.2-14-29.4-33.3-28.9-57.6C48.2,122,48.1,114.8,48.1,107.5L48.1,107.5z   M212.6,308.9c0.3-0.7,0.6-1.2,0.6-1.6c0-18.2,0.4-36.3-0.2-54.5c-0.4-11.8-10.7-22.2-22.7-22.9c-15.6-0.8-31.3-0.6-46.9-0.5  c-1.7,0-3.7,1.4-5,2.6c-6.9,6.7-13.7,13.6-20.5,20.5c-4.6,4.5-6.8,4.5-11.3,0.1c-6.9-6.8-13.6-13.7-20.6-20.4  c-1.5-1.5-3.9-2.8-5.9-2.8c-13.8-0.2-27.7-0.4-41.5,0C21,229.9,11,240.6,10.9,258.1c-0.1,15.2,0,30.3,0,45.5c0,1.8,0.2,3.5,0.3,5.3  L212.6,308.9L212.6,308.9z M134,64.7l-1.3,0.2c-1.9,6.5-3.6,13.1-5.7,19.5c-3.9,12.4-14.6,21.1-27.7,21.7c-11.5,0.6-23,0.5-34.4,0.2  c-4.7-0.1-6.7,1.1-6.3,6.1c0.4,6,0.2,12,0.2,18c-0.2,30.8,20.3,53.3,50.9,55.7c22.7,1.8,46-14.4,52.8-36.2  c4.2-13.4,2.4-27.1,2.9-40.6c0-1-1.4-2.9-2.4-3.1c-14-2.1-20.9-11.1-23.9-24C137.6,76.3,135.7,70.6,134,64.7L134,64.7z M112.1,243.6  c7.1-7.3,13.4-13.7,19.6-20.2c0.7-0.7,1.4-1.7,1.4-2.5c0.1-8.9,0.1-17.8,0.1-27.3c-14.4,4.8-28.1,4.5-42.3,0.1c0,9.3,0,18,0.1,26.6  c0,0.9,0.4,2,1.1,2.7C98.4,229.5,104.9,236.2,112.1,243.6z"
                                    />
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M192,212.6c0-10.7,0-21.2,0-31.7c0-29.5,0-59,0-88.5c0.1-41.8-28.1-75.3-69.5-81C77.4,5.3,38.8,37,33.5,77.9  c-2.4,18.7-1.3,37.9-1.4,56.9c-0.2,24.3-0.1,48.6,0.1,73c0,4.3-1,5.7-5.5,5.8c-4.7,0-5.4-1.9-5.4-6c0.1-38.3,0-76.6,0.1-114.9  c0.1-41,24.7-75.8,62.2-88C134.8-12,188.7,18,200.2,70.6c3,13.6,2.4,28.1,2.5,42.2c0.3,30.6,0.1,61.3,0,92  C202.8,214.2,202.5,214.4,192,212.6L192,212.6z"
                                    />
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M131.8,155.1c-0.5,11.2-9.2,19.6-19.7,19.5c-10.7,0-19-8.1-19.8-19.4c4.1-0.9,7.6-1.9,9.3,4.1  c1.3,4.6,5.6,6.9,10.5,6.9s9.3-2.1,10.5-6.8C124.1,153.7,127.5,154.4,131.8,155.1L131.8,155.1z"
                                    />
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M85.5,118.7c5,0,9.2,4.2,9.1,9.3c-0.1,5-4.3,9.1-9.3,9.1c-5,0-9.2-4.3-9.1-9.3  C76.2,122.8,80.5,118.7,85.5,118.7L85.5,118.7z"
                                    />
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M148,127.7c0.1,4.9-4.1,9.2-9.1,9.3c-5.1,0.1-9.2-3.9-9.3-9c-0.1-5.1,3.9-9.2,9-9.3  C143.7,118.7,147.9,122.7,148,127.7L148,127.7z"
                                    />
                                  </svg>
                                </Link>
                              )}
                              <div className="d-flex flex-column userInfo">
                                <p className="card-text h-auto">
                                  # {index + 1}
                                </p>
                                <h5 className="card-title mt-0">
                                  <Link
                                    to={`/profile/${item.username}`}
                                    reloadDocument={true}
                                  >
                                    {item.firstName} {item.lastName}
                                  </Link>
                                </h5>
                                <div className="d-flex gap-3 userStats">
                                  <div className="d-flex gap-1 align-items-center">
                                    <i className="fal fa-file-signature"></i>
                                    {item.postCount}
                                  </div>
                                  <div className="d-flex gap-1 align-items-center">
                                    <i className="fal fa-badge-check"></i>
                                    {item.awardedBadges.length}
                                  </div>
                                  <div className="d-flex gap-1 align-items-center">
                                    <i className="fal fa-user-friends"></i>
                                    {item?.followers?.length}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {IsOwned[item?.userId] ? (
                              <Button
                                className="w-auto h-auto btn-default-3"
                                onClick={() => navigate("/account")}
                                style={{
                                  padding: "10px 30px",
                                }}
                              >
                                EDIT
                              </Button>
                            ) : following[item?.userId] ? (
                              <Button
                                className="w-auto h-auto btn-default-3"
                                onClick={() => {
                                  unfollowUserHandler(item?.userId);
                                }}
                                style={{
                                  padding: "10px 30px",
                                }}
                              >
                                Following
                              </Button>
                            ) : (
                              <Button
                                className="w-auto h-auto btn-default-2"
                                onClick={() => {
                                  followUserHandler(item?.userId);
                                }}
                                style={{
                                  padding: "10px 30px",
                                }}
                              >
                                Follow
                              </Button>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
            )}
          </div>
        )}
      </section>
    </Tab.Pane>
  );
};

export default UserSearch;

import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Tab } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequestForm, uploadURL } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Loader from "../../Loader";
import ImageCard from "../Pages/ImageCard";

const PostsSearch = ({ search, tab }) => {
  const [searchPostsPage, setSearchPostsPage] = useState(1);
  const [searchPostEnded, setSearchPostEnded] = useState(true);
  const [searchPostLoading, setSearchPostLoading] = useState(true);
  const [searchError, setSearchError] = useState("");
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const [isBookmark, setIsBookmark] = useState({});
  const [isLiked, setIsLiked] = useState({});
  const [loading, setLoading] = useState(false);

  const loadPostsBySearch = async (search, tab) => {
    try {
      // setSearchPostLoading(true);
      setSearchError("");
      const { result, error } = await getRequest(
        `/api/public/search/posts`,
        "",
        {
          search: search,
          page: searchPostsPage,
          limit: 6,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        if (result?.data?.result?.length !== 0) {
          setSearchError("");
          // if(!isPaginate){
          //   setSearchPostsPage((prevPage) => prevPage + 1);
          // }
          if (searchPostsPage === 1) {
            setAllData(result?.data.result);
            // Inside the function where you load more data
            setAllDataLength(result?.data.result);
          } else {
            setAllData((prevData) => [...prevData, ...result?.data.result]);
            // Inside the function where you load more data
            setAllDataLength(allData.length);
          }
          if (result?.data.currentPage === result?.data.totalPages) {
            setSearchPostEnded(false);
          }

          setSearchPostsPage((prevPage) => prevPage + 1);
        } else {
          setSearchError("No Posts Found");
          setAllData([]);
          setAllDataLength(0);
          setSearchPostEnded(true);
        }
      } else if (error?.response?.status === 400) {
        setSearchError(error?.response?.data?.message);
      }
    } catch (error) {
      setSearchError(error.message);
    } finally {
      setSearchPostLoading(false);
    }
  };

  const handleBookmarkPost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/bookmark-post`,
        token,
        { postIdToToggle: postId }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsBookmark((prev) => {
          const newState = { ...prev };
          if (newState[postId]) {
            delete newState[postId];
          } else {
            newState[postId] = true;
          }

          toast.success(
            `Post has been ${
              newState[postId] ? "Bookmarked" : "Un-Bookmarked"
            }!`,
            {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
            }
          );

          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLikePost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/like-post`,
        token,
        { postId }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsLiked((prev) => {
          const newState = { ...prev };
          const isLiked = newState[postId] ? false : true;

          if (isLiked) {
            newState[postId] = true;
          } else {
            delete newState[postId];
          }

          setAllData((prevData) =>
            prevData.map((item) =>
              item._id === postId
                ? {
                    ...item,
                    likeCount: isLiked
                      ? item.likeCount + 1
                      : item.likeCount - 1,
                  }
                : item
            )
          );

          toast.success(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
          });

          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedLoadPostsBySearch = useCallback(
    debounce((search) => {
      loadPostsBySearch(search);
    }, 500),
    []
  );

  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        setLoading(true);
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { likedPost, bookmarks } = result?.data.userPrefrences;
            setIsLiked(
              likedPost.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              )
            );
            setIsBookmark(
              bookmarks.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              )
            );
          }
        } else {
          setIsLiked({});
          setIsBookmark({});
        }
      } catch (error) {
        console.error("Error fetching user preferences:", error);
        setIsLiked({});
        setIsBookmark({});
      } finally {
        setLoading(false);
      }
    };
  }, []);

  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);

  useEffect(() => {
    if (tab === "posts") {
      setSearchPostsPage(1);
      setSearchPostEnded(true);
      setSearchPostLoading(true);
      setSearchError("");
      setAllData([]);
      setAllDataLength(0);
      setIsBookmark({});
      setIsLiked({});
      setLoading(false);
      debouncedLoadPostsBySearch(search);
    }
  }, [search, tab]);
  return (
    <Tab.Pane eventKey={"posts"}>
      {searchPostLoading ? (
        <Loader loading={true} />
      ) : (
        <div>
          {searchError ? (
            searchError
          ) : (
            <InfiniteScroll
              dataLength={allDataLength}
              next={() => {
                console.log(
                  "next",
                  searchPostsPage,
                  searchPostEnded,
                  allDataLength
                );
                loadPostsBySearch(search);
              }}
              inverse={false}
              hasMore={searchPostEnded}
              loader={<Loader loading={true} />}
              scrollThreshold={0.9}
              endMessage={
                <p
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <b>Yay! You've seen it all!</b>
                </p>
              }
            >
              <Row className="">
                {allData.map((item, index) => (
                  <ImageCard
                    image={uploadURL + item?.featuredImage}
                    authorImage={
                      item.userId?.profileImage
                        ? uploadURL + item.userId.profileImage
                        : ""
                    }
                    subCatName={`${item.subCategoryId?.name}`}
                    productSlug={`${item?.productId?.slug}`}
                    catSlug={`${item?.categoryId?.slug}`}
                    subCatSlug={`${item.subCategoryId?.slug}`}
                    authorName={`${item.userId.firstName}`}
                    views={item.viewCount}
                    comments={item.comments}
                    likes={item.likeCount}
                    title={item.title}
                    date={item.createdAt}
                    username={item.userId.username}
                    link={`/${item.productId?.slug}/${item.categoryId?.slug}/${item.slug}`}
                    bookmarkHandler={() => handleBookmarkPost(item._id)}
                    isBookmarked={isBookmark[item._id]}
                    likeHandler={() => handleLikePost(item._id)}
                    isLiked={isLiked[item._id]}
                    reloadDocument={true}
                  />
                ))}
              </Row>
            </InfiniteScroll>
          )}
        </div>
      )}
    </Tab.Pane>
  );
};

export default PostsSearch;

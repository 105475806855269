import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Image,
  InputGroup,
  Nav,
  Navbar,
  Tab,
} from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { uploadURL } from "../../helper/api";
import Layout from "../../layouts/public/Layout";
import PostsSearch from "../../components/public/Search/PostsSearch";
import UserSearch from "../../components/public/Search/UserSearch";

const SearchPage = ({ settings, logo, searchValue, setSearch, tab }) => {
  const [inputValue, setInputValue] = useState(searchValue); // Local state for input
  const navigate = useNavigate();
  const location = useLocation(); // Use this to track the previous path

  // Function to handle the close button behavior
  const handleClose = () => {
    if (location.state && location.state.from) {
      // Go back to the previous page if history exists
      navigate(location.state.from);
    } else {
      // If no history, redirect to a default page (e.g., homepage)
      navigate("/");
    }
  };
  useEffect(() => {
    setInputValue("")
  }, [tab]);

  return (
    <>
      <button
        type="button"
        className="btn-close btn-md px-3 py-3"
        aria-label="Close"
        onClick={handleClose} // Call handleClose on button click
      ></button>
      <Navbar expand={true}>
        <Container className="flex-column flex-md-row flex-lg-row">
          <Link className="navbar-brand" to={settings?.site_link}>
            <Image fluid src={uploadURL + logo} alt="" />
          </Link>
          <Navbar.Collapse id="basic-navbar-nav">
            <Form
              className="w-100 searchFormPublic"
              onSubmit={(e) => {
                e.preventDefault();
                setSearch(inputValue); // Set the search state on submit
              }}
            >
              <InputGroup size="lg">
                <Form.Control
                  type="search"
                  placeholder={
                    tab === "posts" ? "Enter Post Name" : "Enter Creator Name"
                  }
                  value={inputValue} // Local input value
                  onChange={(e) => {
                    setInputValue(e.target.value); // Update local state
                  }}
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2" className="p-0">
                  <Button
                    type="submit"
                    className="btn btn-search bg-transparent border-0 "
                  >
                    <FontAwesomeIcon icon={faSearch} color="#e40d7d" />
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div id="searchResult">
        <section id="listing_main">
          <div className="listing_sec">
            <Container className="p-0">
              <Tab.Container id="myTab" activeKey={tab}>
                <Nav variant="tabs" as="ul" className="justify-content-center">
                  <Nav.Item as="li">
                    <Link
                      to={"/search/posts"}
                      className={`nav-link btn ${tab === "posts" && "active"} `}
                    >
                      Posts
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link
                      to={"/search/users"}
                      className={`nav-link btn ${tab === "users" && "active"} `}
                    >
                      Users
                    </Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="my-5">
                  <PostsSearch search={searchValue} tab={tab}/>
                  <UserSearch search={searchValue} tab={tab}/>
                </Tab.Content>
              </Tab.Container>
            </Container>
          </div>
        </section>
      </div>
    </>
  );
};

const Search = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState(""); // Search state

  useEffect(() => {
    setSearch(""); // Reset search on tab change
    if (!tab) {
      navigate("/search/posts");
    }
  }, [tab, navigate]);

  const [logo, settings] = [null, null]; // Placeholder values


  return (
    <Layout showFooter={false} showHeader={false}>
      <SearchPage
        logo={logo}
        settings={settings}
        searchValue={search} // Pass the actual search value
        setSearch={setSearch} // Function to update the search state
        tab={tab}
      />
    </Layout>
  );
};

export default Search;

import React, { useCallback, useMemo } from "react";
import { Button, Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChain,
  faPaperPlane,
  faPencilAlt,
  faPlane,
  faPlaneDeparture,
  faPlaneUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import ReactSelect from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  deleteRequest,
  getRequest,
  postRequestForm,
} from "../../../helper/api";
import Layout from "../../../layouts/admin/Layout";
import PostByChallenges from "./PostByChallenges";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Loader from "../../../components/Loader";
import {
  getFilterHistory,
  updateFilterHistory,
} from "../../../helper/filterHistory";

const AllChallenges = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("");
  const [product, setProduct] = useState({});
  const [totalChallenges, setTotalChallenges] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getAllChallenges = async (
    page = 1,
    limit = 10,
    search = "",
    status = null,
    product = null,
    startDate = "",
    endDate = ""
  ) => {
    try {
      setCurrentPage(page);
      setPerPage(limit);
      setSearchItem(search);
      setStatusFilter(status);
      setProduct(product);
      setStartDate(startDate);
      setEndDate(endDate);
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/challenge/get-all-challenges`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          status: status?.value,
          startDate: startDate,
          endDate: endDate,
          productId: product?._id,
        }
      );

      setTableHead([
        {
          name: "Challenge Name",
          sortable: true,
          selector: (row) => row.name,
        },
        {
          name: "Sub Category",
          sortable: true,
          selector: (row) => row.subCategoryId?.name,
        },
        {
          name: "Category",
          sortable: true,
          selector: (row) => row.categoryId?.name,
        },
        {
          name: "Product Type",
          sortable: true,
          selector: (row) => row.productId?.name,
        },
        {
          name: "Challenges Status",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              {row?.status === "active" && (
                <span className="badge bg-success">
                  {row?.status.capitalize()}
                </span>
              )}
              {(row?.status === "deactive" || row?.status === "rejected") && (
                <span className="badge bg-danger">
                  {row?.status.capitalize()}
                </span>
              )}
              {row?.status === "schedule" && (
                <span className="badge bg-warning">
                  {row?.status.capitalize()}
                </span>
              )}
            </>
          ),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editChallenge(row._id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="info"
                onClick={() =>
                  copySingleMedia(row.featuredImage, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faChain} />
              </Button>
              {/* <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() => deleteChallenge(id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button> */}
            </>
          ),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        setTableData(response?.result?.data?.challenges);
        setTotalChallenges(response?.result?.data?.totalChallenges);
      } else {
        setTableData([]);
        setTotalChallenges(0);
      }
    } catch (error) {
      console.log("Get All Categories Error", error);
    } finally {
      setPending(false);
    }
  };

  const getCurrentUrl = () => {
    const url = window.location.origin;
    // const url = "https://niosse.com" + location.pathname;
    return url;
  };
  const copySingleMedia = async (featuredImage, row, index, column, id) => {
    try {
      copyToClipboard(
        `${getCurrentUrl()}/${row.productId?.slug}/challenges/${row.slug}`
      );
      toast.success("Copied to Clipboard!", {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      console.log("Challenge URL copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy Challenge URL:", error);
    }
  };

  const copyToClipboard = (clipboardText) => {
    // Create a temporary input element
    const tempInput = document.createElement("input");
    // Set the input value to the clipboardText
    tempInput.value = clipboardText;
    // Append the input element to the document (but keep it hidden)
    tempInput.style.position = "absolute";
    tempInput.style.left = "-9999px";
    document.body.appendChild(tempInput);
    // Select the input content
    tempInput.select();
    // Copy the selected content to clipboard
    document.execCommand("copy");
    // Remove the temporary input element
    document.body.removeChild(tempInput);
    console.log("Image URL copied to clipboard!", clipboardText);
  };
  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const handlePageChange = (page) => {
    updateFilterHistory(
      {
        page,
        limit: perPage,
        searchItem,
        status: statusFilter,
        product,
        startDate,
        endDate,
      },
      "Challenges",
      navigate
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    updateFilterHistory(
      {
        page: 1,
        perPage: newPerPage,
        searchItem,
        status: statusFilter,
        product,
        startDate,
        endDate,
      },
      "Challenges",
      navigate
    );
  };

  const handleStatusFilter = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        searchItem,
        status: selectedOption,
        product,
        startDate,
        endDate,
      },
      "Challenges",
      navigate
    );
  };

  const handleSearchChallenge = (e) => {
    const searchValue = e.target.value;
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        searchItem: searchValue,
        status: statusFilter,
        product,
        startDate,
        endDate,
      },
      "Challenges",
      navigate
    );
  };

  const handleSelect = (ranges) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        searchItem,
        status: statusFilter,
        product,
        startDate,
        endDate,
      },
      "Challenges",
      navigate
    );
  };

  const handleProductChange = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: perPage,
        searchItem,
        status: statusFilter,
        product: selectedOption,
        startDate,
        endDate,
      },
      "Challenges",
      navigate
    );
  };

  const editChallenge = (challengeId) => {
    navigate(`/editChallenge`, {
      state: { challengeId },
    });
  };

  const csvData = useMemo(() => {
    return tableData?.map((user) => ({
      // id: user._id,
      subject: user.title,
      message: user.details,
      recipients: user.recipients,
      // attachments: user.attachments,
      created_at: moment(user.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const fetchProducts = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/product/get-all-products`,
        token,
        {
          page: page,
          search: search,
          status: "active",
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, products } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: products,
            hasMore: false,
          };
        } else {
          return {
            options: products,
            hasMore: products?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Products Error", error);
    }
  };
  useEffect(() => {
    // Parse query parameters from URL on component mount
    getFilterHistory(location, getAllChallenges);
  }, [location]);
  const renderExpandedRow = (row) => {
    const { challengeEndDate, _id } = row.data;
    const isChallengeLive = moment(challengeEndDate).isSameOrAfter(
      moment(new Date()).format("YYYY-MM-DD")
    );

    navigate(`/postByChallenges`, {
      state: { challengeId:_id, isChallengeLive },
    });

    // return (
    //   <PostByChallenges challengeId={_id} isChallengeLive={isChallengeLive} />
    // );
  };
  return (
    <Layout>
      <section id="users_list" className="pt-5">
        <Container>
          <Row className="pb-3">
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search Challenge"
                  className="me-2"
                  value={searchItem}
                  onChange={handleSearchChallenge}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <ReactSelect
                  placeholder="Select Status"
                  options={[
                    { value: "active", label: "Active" },
                    { value: "deactive", label: "Deactive" },
                  ]}
                  value={statusFilter ? statusFilter : ""}
                  onChange={handleStatusFilter}
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <AsyncPaginate
                  value={product ? product : ""}
                  loadOptions={fetchProducts}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  onChange={handleProductChange}
                  placeholder="Select Product"
                  isClearable
                  required
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No Product Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Dropdown autoClose={"outside"} className="w-100">
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        updateFilterHistory(
                          {
                            page: 1,
                            limit: 10,
                            searchItem,
                            status: statusFilter,
                            product,
                            startDate: "",
                            endDate: "",
                          },
                          "Challenges",
                          navigate
                        );
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col>
              {tableData && (
                <CSVLink
                  data={csvData}
                  filename={"challenges.csv"}
                  className="btn btn-success"
                >
                  Export CSV
                </CSVLink>
              )}
            </Col>
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalChallenges} // Assuming you have a total count in your API response
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
              expandableRows
              expandableRowsComponent={renderExpandedRow}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default AllChallenges;

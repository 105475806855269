import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import PublicImages from "../../constants/public/images";
import Layout from "../../layouts/auth/Layout";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGoogleLogin } from "@react-oauth/google";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { LoginSocialFacebook } from "reactjs-social-login";
import * as Yup from "yup";
import Loader from "../../components/Loader";
import { postRequestForm } from "../../helper/api";
import { setItemInLocalStorage } from "../../helper/helper";

function LoginV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  const loginHandler = async (values) => {
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(`/api/auth/login`, "", {
        email: values?.email?.toLowerCase(),
        password: values?.password,
      });
      if (result?.status === 200 || result?.status === 201) {
        const { user } = result?.data;
        if (user.role === "admin") {
          navigate(`/otp`, { state: { _id: user._id, pathToGo: "dashboard" } });
        } else {
          navigate(`/otp`, { state: { _id: user._id, pathToGo: "account" } });
        }
      } else if (error?.response?.status === 400) {
        setEmail(values.email);
        setPassword(values.password);
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      alert("Please enter correct email or password");
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Layout>
      <section className="login">
        <Container>
          <Row className="bod p-6 align-items-center ">
            <Col lg={6} md={12} xs={12} className="d-none d-lg-block">
              <Image fluid src={PublicImages.SigninImage} alt="" />
            </Col>
            <Col lg={6} md={12} xs={12} className="signin">
              {loading ? (
                <Loader loading={loading} />
              ) : (
                <Formik
                  initialValues={{ email, password }}
                  validationSchema={LoginSchema}
                  onSubmit={loginHandler}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="text-center">
                        <h1 className="Sign">Sign in</h1>
                        <p className="access mb-3">
                          Register once to unlock access to this content and all
                          other resources available on Womanii
                        </p>
                      </div>
                      <InputGroup className="justify-content-center">
                        <Row className="justify-content-center align-items-center mb-3 w-100">
                          <Col
                            xl="12"
                            lg="12"
                            md="12"
                            sm="12"
                            className="mb-3 googleLoginContainer p-0 ps-md-1 p-lg-0 ps-xl-1"
                          >
                            <div className="googleAuth">
                              
                            </div>
                          </Col>
                        </Row>
                      </InputGroup>
                      <InputGroup className="mb-3" size="lg">
                        <Form.Control
                          type="email"
                          placeholder="Email Address  *"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          defaultValue={values.email}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3" size="lg">
                        <Form.Control
                          type={isShowPassword ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          style={{ borderRight: "none" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values.password}
                          className="form-control"
                        />
                        <InputGroup.Text
                          style={{
                            background: "#fff",
                            borderLeft: "none",
                          }}
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        >
                          <FontAwesomeIcon
                            icon={isShowPassword ? faEyeSlash : faEye}
                            size="lg"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <div id="status">
                        <div
                          className={`alert ${
                            (errors.email && touched.email) ||
                            (errors.password && touched.password)
                              ? ""
                              : "d-none"
                          } ${errors ? "alert-danger" : "alert-success"}`}
                          role="alert"
                        >
                          {errors.email && touched.email
                            ? errors.email
                            : errors.password && touched.password
                            ? errors.password
                            : ""}
                        </div>
                      </div>
                      <InputGroup className="mb-3">
                        <Button type="submit" className="btn-default w-100">
                          Sign in
                        </Button>
                      </InputGroup>
                      <InputGroup className="justify-content-center justify-content-lg-end forgetPassLink mb-3">
                        <Link className="btn  p-0" to="/forget-password">
                          Forgot Password?
                        </Link>
                      </InputGroup>

                      <div className="mt-3 text-center">
                        <p>
                          Do You Want to Create An Account{" "}
                          <Link className="btn p-0" to="/sign-up">
                            <b>Sign Up</b>
                          </Link>
                        </p>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default LoginV2;

import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import YouTube from "react-youtube";
import { Comment, Header } from "semantic-ui-react";
import Loader from "../../components/Loader";
import ChallengeTimer from "../../components/public/Pages/ChallengeTimer";
import Comments from "../../components/public/Pages/Comments";
import {
  getRequest,
  postRequest,
  postRequestForm,
  uploadURL,
} from "../../helper/api";
import { getItemFromLocalStorage } from "../../helper/helper";
import Layout from "../../layouts/public/Layout";
import { socket } from "../../helper/socket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as Liked,
  faEye,
  faPaperPlane,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as NotLiked } from "@fortawesome/free-regular-svg-icons";
// moment.tz.setDefault("Asia/Kolkata");

const Live = () => {
  const navigate = useNavigate();
  const { liveStream } = useParams();
  const [loading, setLoading] = useState(true);
  const [liveStreamData, setLiveStreamData] = useState({});
  const [player, setPlayer] = useState(false);
  const [content, setContent] = useState("");
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [comments, setComments] = useState([]);
  const [sendingComment, setSendingComment] = useState(false);
  const [isLiked, setIsLiked] = useState({});

  const getLiveStream = async () => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/liveStreaming/get-live-streaming`,
        "",
        {
          liveStreamingId: liveStream,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { liveStreaming } = result?.data;
        setLiveStreamData(liveStreaming);
        getComments(liveStreaming?._id);
        postViewCount(liveStreaming?._id);
        getUserLikedAndBookmarkedPosts(liveStreaming?._id);

        if (moment(liveStreaming?.schedule).isAfter(moment(new Date()))) {
          setPlayer(false);
        } else {
          setPlayer(true);
        }
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const onExprireLiveStreamTimer = () => {
    console.log("workinggg");
    var targetElm = document.querySelector(".liveStreamSingleSection");
    targetElm.scrollIntoView();

    setPlayer((prevState) => !prevState);
  };

  const getComments = async (liveStreamingId) => {
    try {
      setCommentsLoading(true);
      const { result, error } = await getRequest(
        `/api/secure/comment/get-all-comments-by-live`,
        "",
        {
          liveStreamingId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setComments(result?.data?.comments);
      } else if (error?.response?.status === 400) {
        console.log(
          "Get Comments Error due to 400 status",
          error?.response?.data?.message
        );
      }
    } catch (error) {
      console.log("Get Comments Error", error);
    } finally {
      setCommentsLoading(false);
    }
  };
  const postComment = async (livePostId, content) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequest(
        `/api/secure/comment/post-live-comment`,
        token,
        {
          livePostId,
          content,
          socketId: socket?.id,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success("Comment Posted!", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Post Comment Error", error);
    } finally {
      setContent("");
      setSendingComment(false);
    }
  };

  const getUserLikedAndBookmarkedPosts = async (liveStreamId) => {
    try {
      setLoading(true);
      const storedUser = JSON.parse(getItemFromLocalStorage("USER"));

      if (storedUser && storedUser?.username) {
        const { result, error } = await getRequest(
          `/api/secure/user/profile/${storedUser?.username}`
        );

        if (result?.status === 200 || result?.status === 201) {
          const { userPrefrences } = result?.data;
          const { likedLivePost } = userPrefrences;
          setIsLiked((prev) => ({
            ...prev,
            ...likedLivePost.reduce(
              (acc, liveStreamId) => ({ ...acc, [liveStreamId]: true }),
              {}
            ),
          }));
        }
      } else {
        setIsLiked({});
      }
    } catch (error) {
      console.error("Error while fetching user:", error);
      setIsLiked({});
    } finally {
      setLoading(false);
    }
  };

  const postViewCount = async (livePostId) => {
    try {
      const { result, error } = await postRequest(
        `/api/secure/userPreferences/view-live-post`,
        "",
        {
          livePostId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        console.log("View Count Posted");
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Post View Count Error", error);
    } finally {
    }
  };

  const handleLikePost = async (livePostId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/like-live-post`,
        token,
        {
          livePostId: livePostId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsLiked((prev) => {
          const newState = { ...prev };
          let isLiked = false;

          if (newState[livePostId]) {
            // If post is already liked, remove it
            delete newState[livePostId];
            isLiked = false;
          } else {
            // If post is not liked, add it
            newState[livePostId] = true;
            isLiked = true;
          }

          // Update the like count in the corresponding post data

          setLiveStreamData((prevData) => ({
            ...prevData,
            likeCount: isLiked
              ? prevData.likeCount + 1
              : prevData.likeCount - 1,
          }));

          if (isLiked) {
            toast.success(`Live has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(`Live has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }

          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLiveStream();
    const user = getItemFromLocalStorage("USER");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, [liveStream]);

  useEffect(() => {
    console.log("socket", socket?.id);
    if (socket) {
      const socketId = socket?.id;
      socket.emit("join", { socketId });
      console.log(`Emitted join event for socket ID: ${socketId}`);

      socket.on("newLiveComment", (data) => {
        getComments(data.postId)
        // setComments((previous) => [...previous, data]);
        console.log(data,liveStreamData);
      });
    }

    return () => {
      if (socket) {
        socket.off("newLiveComment");
      }
    };
  }, []);
  return (
    <Layout>
      <main id="live_main" className="creators_sec">
        {loading ? (
          <Loader loading={true} />
        ) : (
          <Container className="bg-light p-3 p-md-5">
            <section className="liveStreamSingleSection mb-5">
              {player ? (
                <YouTube
                  videoId={liveStreamData?.videoId}
                  id={liveStreamData?.videoId}
                  className="liveStreamVideoContainer"
                  iframeClassName="liveStreamVideo"
                  opts={{
                    playerVars: {
                      rel: 0,
                      autoplay: 1,
                    },
                  }}
                />
              ) : (
                <div className="liveStreamVideoContainer">
                  <Image
                    fluid
                    className="liveStreamVideo w-100"
                    style={{ objectFit: "cover" }}
                    src={`https://img.youtube.com/vi/${liveStreamData?.videoId}/maxresdefault.jpg`}
                    alt=""
                  />
                </div>
              )}
            </section>

            <section className="mid">
              <Container>
                <Row className="align-items-center">
                  <Col
                    xs={12}
                    className="gap-2  d-flex justify-content-center justify-content-md-start mb-3 mb-md-0"
                  >
                    <span>
                      <FontAwesomeIcon icon={faEye} />{" "}
                      {liveStreamData.viewCount}
                    </span>
                    <span
                      className="likes"
                      onClick={() => handleLikePost(liveStreamData._id)}
                      style={{ cursor: "pointer" }}
                    >
                      {console.log("isLiked", isLiked)}
                      {isLiked[liveStreamData._id] ? (
                        <FontAwesomeIcon icon={Liked} color="#e5097d" />
                      ) : (
                        <FontAwesomeIcon icon={NotLiked} />
                      )}{" "}
                      {liveStreamData.likeCount}
                    </span>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="liveStreamSingleSection mb-5">
              <Row>
                <Col md={8}>
                  <h1 className="text-start mb-3 pb-2">Title</h1>
                  <p
                    style={{
                      color: "#828282",
                      fontFamily: "Overpass-Medium",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {liveStreamData?.title}
                  </p>
                </Col>
                <Col md={4} className="text-start text-md-end">
                  <h1 className="text-start text-md-end">
                    {moment(liveStreamData?.schedule).format(
                      "MMM DD, YYYY | h:mm A"
                    )}
                  </h1>
                </Col>
              </Row>
            </section>
            <section className="liveStreamSingleSection mb-5">
              <Row>
                <Col md={12}>
                  <h1 className="text-start mb-3 pb-2">Description</h1>
                  <p
                    style={{
                      color: "#828282",
                      fontFamily: "Overpass-Medium",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {liveStreamData?.description}
                  </p>
                </Col>
              </Row>
            </section>
            <section
              className="expertDeaitlsContainer p-3 p-md-5 mb-3"
              style={{
                background: "#EAF1F3",
              }}
            >
              <h1 className="text-start mb-3 pb-2">Expert Details</h1>
              {liveStreamData?.expertDetails?.map(
                (
                  { expertName, expertDescription, expertOrganization },
                  index
                ) => (
                  <div
                    className="d-flex gap-5 mb-3 flex-column flex-md-row"
                    key={index}
                  >
                    <div className="mb-2">
                      <h4
                        className="mb-2"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontFamily: "Overpass-Medium",
                        }}
                      >
                        <b>Expert Name</b>
                      </h4>
                      <p
                        style={{
                          color: "#828282",
                          fontWeight: "500",
                          fontFamily: "Overpass-Medium",
                          fontSize: "16px",
                        }}
                      >
                        {expertName}
                      </p>
                    </div>
                    <div className="mb-2 px-0 px-md-3">
                      <h4
                        className="mb-2"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontFamily: "Overpass-Medium",
                        }}
                      >
                        <b>Expert Designation</b>
                      </h4>
                      <p
                        style={{
                          color: "#828282",
                          fontWeight: "500",
                          fontFamily: "Overpass-Medium",
                          fontSize: "16px",
                        }}
                      >
                        {expertDescription}
                      </p>
                    </div>
                    <div className="mb-2">
                      <h4
                        className="mb-2"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontFamily: "Overpass-Medium",
                        }}
                      >
                        <b>Organization</b>
                      </h4>
                      <p
                        style={{
                          color: "#828282",
                          fontWeight: "500",
                          fontFamily: "Overpass-Medium",
                          fontSize: "16px",
                        }}
                      >
                        {expertOrganization}
                      </p>
                    </div>
                  </div>
                )
              )}
            </section>
            <section className="expertDeaitlsContainer my-5">
              <h1 className="text-start mb-3 pb-2">Anchor Details</h1>
              {liveStreamData?.anchorDetails?.map(
                ({ anchorName, anchorDescription }, index) => (
                  <div
                    className="d-flex gap-5 mb-3 flex-column flex-md-row"
                    key={index}
                  >
                    <div className="mb-2">
                      <h4
                        className="mb-2"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontFamily: "Overpass-Medium",
                        }}
                      >
                        <b>Anchor Name</b>
                      </h4>
                      <p
                        style={{
                          color: "#828282",
                          fontWeight: "500",
                          fontFamily: "Overpass-Medium",
                          fontSize: "16px",
                        }}
                      >
                        {anchorName}
                      </p>
                    </div>
                    <div className="mb-2">
                      <h4
                        className="mb-2"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          fontFamily: "Overpass-Medium",
                        }}
                      >
                        <b>Anchor Designation</b>
                      </h4>
                      <p
                        style={{
                          color: "#828282",
                          fontWeight: "500",
                          fontFamily: "Overpass-Medium",
                          fontSize: "16px",
                        }}
                      >
                        {anchorDescription}
                      </p>
                    </div>
                  </div>
                )
              )}
            </section>
            <section className="liveStreamSingleSection mb-5">
              {!player &&
                moment(liveStreamData?.schedule).isAfter(
                  moment(new Date())
                ) && (
                  <ChallengeTimer
                    expiryTimestamp={new Date(liveStreamData?.schedule)}
                    onExpire={() => onExprireLiveStreamTimer()}
                  />
                )}
            </section>
            {/* ----------------------comment section---------------------- */}
            {comments.length > 0 && (
              <section className="mid7">
                <Comment.Group className="mw-100" threaded size="large">
                  <Header as="h3" dividing>
                    Comments
                  </Header>
                  {commentsLoading ? (
                    <Loader loading={commentsLoading} />
                  ) : (
                    comments?.map((item, index) => (
                      <Comments {...item} key={index} />
                    ))
                  )}
                </Comment.Group>
              </section>
            )}

            {getItemFromLocalStorage("TOKEN") && (
              <section className="commentForm">
                <h5 className="mb-3">Post a Comment</h5>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSendingComment(true);
                    postComment(liveStreamData._id, content);
                  }}
                  className="commentFormCover"
                >
                  <div
                    className="d-flex gap-2 gap-md-4  align-items-center flex-grow-1  commentFormInputCover"
                    style={{
                      background: "#EAF1F3",
                    }}
                  >
                    {user?.profileImage ? (
                      <Image
                        src={uploadURL + user?.profileImage}
                        fluid
                        alt=""
                        height={40}
                        width={40}
                        className="m-0"
                      />
                    ) : (
                      <></>
                    )}
                    <InputGroup>
                      <FormControl
                        type="text"
                        placeholder="Write Here"
                        onChange={(e) => {
                          setContent(e.target.value);
                        }}
                        value={content}
                        style={{
                          background: "transparent",
                        }}
                      />
                    </InputGroup>
                    <Button
                      className="commentFormSubmit w-auto bg-transparent border-0 p-0 me-2"
                      style={{
                        fontSize: "22px",
                      }}
                      disabled={sendingComment}
                      type="submit"
                    >
                      <FontAwesomeIcon
                        icon={sendingComment ? faSpinner : faPaperPlane}
                        className={sendingComment ? "fa-spin" : ""}
                        color="#e32975"
                      />
                    </Button>
                  </div>
                </Form>
              </section>
            )}
          </Container>
        )}
      </main>
    </Layout>
  );
};

export default Live;

import React from "react";
import Skeleton from "react-loading-skeleton";

export default function SkeletonLoader({
  loading = false,
  height = 0,
  width = 0,
  count = 1,
  gap = "10px",
  isVertical = true
}) {
  return (
    loading && (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap,
          flexDirection: isVertical ? "column" : "row",
        }}
      >
        <Skeleton
          baseColor="#fff0f73b"
          highlightColor="#fff0f7"
          height={height}
          width={width}
          count={count}
          inline={!isVertical}
        />
      </div>
    )
  );
}

import React, { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./helper/routes";
import { ToastContainer } from "react-toastify";
import { PrimeReactProvider } from "primereact/api";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import "swiper/css";
import 'swiper/css/pagination';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/counter/lib/plugin.css";
import "@draft-js-plugins/hashtag/lib/plugin.css";
import "semantic-ui-css/semantic.min.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-h5-audio-player/lib/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";

import Error404 from "./views/Error404";
import Loader from "./components/Loader";
import { connectToSocket } from "./helper/socket";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemInLocalStorage,
} from "./helper/helper";
import { getRequest, postRequest } from "./helper/api";
import { GoogleOAuthProvider } from "@react-oauth/google";

const getRoutes = (routes, user) => {
  return routes.map((prop, key) => {
    if (
      prop?.layout === "ADMIN" &&
      user !== null &&
      (user.permissions.includes(prop?.name) ||
        user.permissions.includes("Super Admin"))
    ) {
      if (prop?.subMenu) {
        return prop.subMenu.map((prop, key) => (
          <Route
            exact
            path={`${prop.path}`}
            element={<prop.component />}
            key={key}
          />
        ));
      } else {
        return (
          <Route
            exact
            path={`${prop.path}`}
            element={<prop.component />}
            key={key}
          />
        );
      }
    } else {
      return (
        <Route
          exact
          path={`${prop.path}`}
          element={<prop.component />}
          key={key}
        />
      );
    }
  });
};

const App = () => {
  const [user, setUser] = useState(null); // Maintain the user state
  const [isLoading, setIsLoading] = useState(true); // Maintain the loading state

  const getUser = useMemo(() => {
    return async () => {
      try {
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            setUser(result?.data?.user);
            const userPrefrences = JSON.stringify(result?.data?.userPrefrences);
            setItemInLocalStorage("USER_PREFRENCES", userPrefrences);
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setUser(null);
      } finally {
        setIsLoading(false); // Ensure isLoading is set to false
      }
    };
  }, []);

  // useEffect(() => {
  //   // if (!isConnected) {
  //   socket.connect();
  //   // }

  //   function onConnect() {
  //     console.log("Connected");
  //   }

  //   function onDisconnect() {
  //     console.log("Disconnected");
  //   }

  //   socket.on("connect", onConnect);
  //   socket.on("disconnect", onDisconnect);

  //   return () => {
  //     socket.off("connect", onConnect);
  //     socket.off("disconnect", onDisconnect);
  //   };
  // }, []);
  // useEffect(() => {
  //   // const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
  //   // if (storedUser && storedUser?.username) {
  //   //   setUser(storedUser); // Set the user from local storage
  //   //   setIsLoading(false); // Set isLoading to false
  //   // } else {
  //   //   // If user is not in local storage, make the API request

  //   // }
  //   connectToSocket();

  //   getUser();
  // }, [getUser]);
  const countVisit = async () => {
    try {
      const response = await postRequest(`/api/secure/visitors/visit`);
      console.log("response", response);
    } catch (error) {
      console.log("visitor count Error", error);
    } finally {
    }
  };
  useEffect(() => {
    countVisit();
    const handleBeforeUnload = () => {
      // Keep specific items in localStorage and delete the rest
      const itemsToKeep = ["TOKEN", "ROLE", "USER","StartUpPopupShown"];
      const localStorageKeys = Object.keys(localStorage);

      localStorageKeys.forEach((key) => {
        if (!itemsToKeep.includes(key)) {
          removeItemFromLocalStorage(key);
        }
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    connectToSocket();
    getUser();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [getUser]);

  useEffect(() => {
    const handleHardRefresh = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.key === "R"
      ) {
        // Warn the user before clearing localStorage
        const confirmationMessage =
          "Reloading the page will clear your data. Are you sure?";
        event.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage; // For some older browsers
      }
    };

    window.addEventListener("keydown", handleHardRefresh);

    return () => {
      window.removeEventListener("keydown", handleHardRefresh);
    };
  }, []);

  if (isLoading) {
    return <Loader loading={isLoading} />;
  } else {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <PrimeReactProvider value={{ unstyled: true }}>
          <Router>
            <Routes>
              {getRoutes(routes, user)}
              <Route path="*" element={<Error404 />} />
            </Routes>
            <ToastContainer />
          </Router>
        </PrimeReactProvider>
      </GoogleOAuthProvider>
    );
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

import LOGO from "../../assets/img/logo.png";
import AppleImage from "../../assets/img/appstore.png";
import AndroidImage from "../../assets/img/playstore.png";
import SigninImage from "../../assets/img/image 61.png";
import SignupImage from "../../assets/img/login 1.png";
import ForgetPasswordImage from "../../assets/img/Frame2.png";

import Ad from "../../assets/img/ad.png";
import TrendingPost from "../../assets/img/trending_posts.png";
import Treating1 from "../../assets/img/treating_1.png";

import Treating2 from "../../assets/img/treating_2.png";
import Treating3 from "../../assets/img/treating_3.png";
import Treating4 from "../../assets/img/treating_4.png";

import PopularPost from "../../assets/img/popular_posts.png";
import PopularPost1 from "../../assets/img/popular_post_1.png";
import PopularPost2 from "../../assets/img/popular_post_2.png";
import PopularPost3 from "../../assets/img/popular_post_3.png";
import Categories from "../../assets/img/categories.png";
import Ellipse62 from "../../assets/img/Ellipse 62.png";
import Ellipse63 from "../../assets/img/Ellipse 63.png";
import Ellipse64 from "../../assets/img/Ellipse 64.png";
import Ellipse65 from "../../assets/img/Ellipse 65.png";
import Ellipse66 from "../../assets/img/Ellipse 66.png";
import Ellipse86 from "../../assets/img/Ellipse 86.png";
import Ellipse109 from "../../assets/img/Ellipse 109.png";
import Ellipse125 from "../../assets/img/Ellipse 125.png";

import Creator from "../../assets/img/creator.png";
import Creator1 from "../../assets/img/Creator-1.png";
import Creator2 from "../../assets/img/Creator-2.png";
import Creator3 from "../../assets/img/Creator-3.png";
import ten from "../../assets/img/10mn.png";
import twenty from "../../assets/img/20mn.png";
import thirty from "../../assets/img/30mn.png";
import twentyseven from "../../assets/img/27mn.png";
import aboutus_img from "../../assets/img/aboutus_img.png";
import Add from "../../assets/img/add.png";
import alternate_email from "../../assets/img/alternate_email.png";
import assistant from "../../assets/img/assistant.png";
import backup from "../../assets/img/backup.png";
import banner from "../../assets/img/banner.png";
import banner_2 from "../../assets/img/banner_2.png";
import bookmark_add from "../../assets/img/bookmark_add.png";
import call from "../../assets/img/call.png";
import cam from "../../assets/img/cam.png";
import categories from "../../assets/img/categories.png";
import center from "../../assets/img/center.png";
import comment from "../../assets/img/comment.png";
import creator from "../../assets/img/creator.png";

import drafts from "../../assets/img/drafts.png";
import delete_1 from "../../assets/img/delete_1.png";
import edit_note from "../../assets/img/edit_note.png";
import edit_square from "../../assets/img/edit_square.png";
import face from "../../assets/img/face.png";
import facebook from "../../assets/img/facebook.png";
import file_copy from "../../assets/img/file_copy.png";
import forum from "../../assets/img/forum_1.png";
import frame from "../../assets/img/Frame.png";
import Frame2 from "../../assets/img/Frame2.png";
import Group22 from "../../assets/img/Group 22.png";
import Group83 from "../../assets/img/Group 83.png";
import Group84 from "../../assets/img/Group 84.png";
import Group89 from "../../assets/img/Group 89.png";
import image_16 from "../../assets/img/image 16.png";
import image_36 from "../../assets/img/image 36.png";
import image_37 from "../../assets/img/image 37.png";
import image_38 from "../../assets/img/image 38.png";
import image_39 from "../../assets/img/image 39.png";
import image_43 from "../../assets/img/image 43.png";
import image_45 from "../../assets/img/image 45.png";
import image_60 from "../../assets/img/image 60.png";
import image_61 from "../../assets/img/image 61.png";
import image_63 from "../../assets/img/image 63.png";

import image_65 from "../../assets/img/image 65.png";

import image_67 from "../../assets/img/image 67.png";
import image_68 from "../../assets/img/image 68.png";
import image_69 from "../../assets/img/image 69.png";
import image_70 from "../../assets/img/image 70.png";
import image_71 from "../../assets/img/image 71.png";
import image_72 from "../../assets/img/image 72.png";
import image_73 from "../../assets/img/image 73.png";
import image_74 from "../../assets/img/image 74.png";
import image_75 from "../../assets/img/image 75.png";
import info from "../../assets/img/info.png";
import insta from "../../assets/img/insta.png";
import Isolation_Mode from "../../assets/img/Isolation_Mode.png";
import Isolation_Mode_1 from "../../assets/img/Isolation_Mode_1.png";
import Isolation_Mode_2 from "../../assets/img/Isolation_Mode_2.png";
import left from "../../assets/img/left.png";
import line from "../../assets/img/line.png";
import listing_card_img_1 from "../../assets/img/listing_1.png";
import listing_card_img_2 from "../../assets/img/listing_2.png";
import listing_card_img_3 from "../../assets/img/listing_3.png";
import mail from "../../assets/img/mail.png";
import miden from "../../assets/img/miden.png";
import notifications from "../../assets/img/notifications.png";
import pages from "../../assets/img/pages.png";
import model_1 from "../../assets/img/model_1 (1).png";
import model_2 from "../../assets/img/model_2.png";
import model_3 from "../../assets/img/model_3.png";
import pin_drop from "../../assets/img/pin_drop.png";
import playstore from "../../assets/img/playstore.png";
import podcast from "../../assets/img/podcast.png";
import Rectangle61 from "../../assets/img/Rectangl61.png";
import Rectangle93 from "../../assets/img/Rectangle 93.png";
import Rectangle94 from "../../assets/img/Rectangle 94.png";
import Rectangle95 from "../../assets/img/Rectangle 95.png";

import Rectangle97 from "../../assets/img/Rectangle 97.png";
import Rectangle98 from "../../assets/img/Rectangle 98.png";

import Rectangle100 from "../../assets/img/Rectangle 100.png";
import Rectangle101 from "../../assets/img/Rectangle 101.png";
import Rectangle102 from "../../assets/img/Rectangle 102.png";
import Rectangle103 from "../../assets/img/Rectangle 103.png";
import Rectangle104 from "../../assets/img/Rectangle 104.png";
import Rectangle105 from "../../assets/img/Rectangle 105.png";
import Rectangle115 from "../../assets/img/Rectangle 115.png";
import Rectangle116 from "../../assets/img/Rectangle 116.png";
import Rectangle117 from "../../assets/img/Rectangle 117.png";
import Rectangle118 from "../../assets/img/Rectangle 118.png";
import Rectangle119 from "../../assets/img/Rectangle 119.png";
import Rectangle120 from "../../assets/img/Rectangle 120.png";
import Rectangle121 from "../../assets/img/Rectangle 121.png";
import Rectangle122 from "../../assets/img/Rectangle 122.png";
import Rectangle123 from "../../assets/img/Rectangle 123.png";
import Rectangle124 from "../../assets/img/Rectangle 124.png";
import Rectangle181 from "../../assets/img/Rectangle 181.png";
import Rectangle241 from "../../assets/img/Rectangle 241.png";
import Rectangle261 from "../../assets/img/Rectangle 261.png";
import Rectangle262 from "../../assets/img/Rectangle 262.png";
import Rectangle263 from "../../assets/img/Rectangle 263.png";
import Rectangle264 from "../../assets/img/Rectangle 264.png";
import Rectangle265 from "../../assets/img/Rectangle 265.png";
import Rectangle266 from "../../assets/img/Rectangle 266.png";
import Rectangle267 from "../../assets/img/Rectangle 267.png";
import Rectangle268 from "../../assets/img/Rectangle 268.png";
import Rectangle269 from "../../assets/img/Rectangle 269.png";
import Rectangle270 from "../../assets/img/Rectangle 270.png";
import Rectangle271 from "../../assets/img/Rectangle 271.png";
import Rectangle272 from "../../assets/img/Rectangle 272.png";
import Rectangle277 from "../../assets/img/Rectangle 277.png";
import Rectangle299 from "../../assets/img/Rectangle 299.png";
import Rectangle300 from "../../assets/img/Rectangle 300.png";
import Rectangle301 from "../../assets/img/Rectangle 301.png";
import Rectangle302 from "../../assets/img/Rectangle 302.png";
import Rectangle308 from "../../assets/img/Rectangle 308.png";
import Rectangle311 from "../../assets/img/Rectangle 311.png";

import Rectangle313 from "../../assets/img/Rectangle 313.png";
import Rectangle317 from "../../assets/img/Rectangle 317.png";
import Rectangle318 from "../../assets/img/Rectangle 318.png";
import Rectangle319 from "../../assets/img/Rectangle 319.png";
import Rectangle320 from "../../assets/img/Rectangle 320.png";
import Rectangle321 from "../../assets/img/Rectangle 321.png";
import right from "../../assets/img/right.png";
import coin_1 from "../../assets/img/Rupees-Coin-PNG_wdwlsf 1.png";
import share from "../../assets/img/share.png";
import share2 from "../../assets/img/share2.png";
import share3 from "../../assets/img/share3.png";
import share4 from "../../assets/img/share4.png";
import share5 from "../../assets/img/share5.png";
import slidshow from "../../assets/img/slideshow.png";
import smart_display from "../../assets/img/smart_display.png";
import simg from "../../assets/img/sml-img.png";
import simg1 from "../../assets/img/sml-img1.png";
import teamsimg1 from "../../assets/img/teamsimg1.png";
import treating_1 from "../../assets/img/treating_1.png";
import treating_2 from "../../assets/img/treating_2.png";
import treating_3 from "../../assets/img/treating_3.png";
import treating_4 from "../../assets/img/treating_4.png";
import trending_posts from "../../assets/img/trending_posts.png";
import twitter from "../../assets/img/twiter.png";
import unit_1 from "../../assets/img/unit_1.png";
import unit_2 from "../../assets/img/unit_2.png";
import Vector from "../../assets/img/Vector.png";
import video from "../../assets/img/video.png";
import video2 from "../../assets/img/video2.png";
import go from "../../assets/img/way-to-go 1.png";
import w_logo from "../../assets/img/w-logo.png";
import youtube from "../../assets/img/youtube.png";
import paytm from "../../assets/img/paytm.png";
import lipi from "../../assets/img/lipi.png";
import image3 from "../../assets/img/image 3.png";
import instagram from "../../assets/img/Instagram-Rounded.png";
import facebookFriend from "../../assets/img/facebookFriend.png";
import mic from "../../assets/img/mic.png";

import activeMic from "../../assets/img/activeMic.png";
import activeEditNote from "../../assets/img/activeEditNote.png";
import activeImage from "../../assets/img/activeImage.png";
import activeList from "../../assets/img/activeList.png";
import activeSave from "../../assets/img/activeSave.png";
import activeVideo from "../../assets/img/activeVideo.png";
import BookmarkIcon from "../../assets/img/bookmark.svg";
import BookmarkActive from "../../assets/img/bookmarkActive.svg";
import WhatsAppIcon from "../../assets/img/WhatsAppIcon.png";
import WhatsAppIcon_2 from "../../assets/img/WhatsAppIcon_2.png";
import WhatsAppIcon_3 from "../../assets/img/WhatsAppIcon_3.png";

import Trophy from "../../assets/img/Torphy_icon.png";
const PublicImages = {
  activeMic,
  activeEditNote,
  activeImage,
  activeList,
  activeSave,
  activeVideo,
  BookmarkIcon,
  BookmarkActive,
  Trophy,
  lipi,
  mic,
  paytm,
  LOGO,
  AndroidImage,
  AppleImage,
  SigninImage,
  SignupImage,
  ForgetPasswordImage,
  instagram,
  facebookFriend,
  Ad,
  TrendingPost,
  Treating1,

  Treating2,
  Treating3,
  Treating4,

  PopularPost,
  PopularPost1,
  PopularPost2,
  PopularPost3,
  Categories,
  Ellipse62,
  Ellipse63,
  Ellipse64,
  Ellipse65,
  Ellipse66,
  Creator,
  Creator1,
  Creator2,
  Creator3,
  ten,
  twenty,
  thirty,
  twentyseven,
  aboutus_img,
  Add,
  alternate_email,
  assistant,
  backup,
  banner,
  banner_2,
  bookmark_add,
  call,
  cam,
  categories,
  center,
  comment,
  creator,
  drafts,
  delete_1,
  WhatsAppIcon,
  WhatsAppIcon_2,
  WhatsAppIcon_3,
  edit_note,
  edit_square,
  face,
  facebook,
  file_copy,
  forum,
  frame,
  Frame2,
  Group22,
  Group83,
  Group84,
  Group89,
  image_16,
  image_36,
  image_37,
  image_38,
  image_39,
  image_43,
  image_45,
  image_60,
  image_61,
  image_63,
  image_65,
  image_67,
  image_68,
  image_69,
  image_70,
  image_71,
  image_72,
  image_73,
  image_74,
  image_75,
  info,
  insta,
  Isolation_Mode,

  Isolation_Mode_1,
  Isolation_Mode_2,
  left,
  line,
  listing_card_img_1,
  listing_card_img_2,
  listing_card_img_3,
  mail,
  miden,
  notifications,
  pages,
  model_1,
  model_2,
  model_3,
  pin_drop,
  playstore,
  podcast,
  Rectangle61,
  Rectangle93,
  Rectangle94,
  Rectangle95,

  Rectangle97,
  Rectangle98,
  Rectangle100,
  Rectangle101,
  Rectangle102,
  Rectangle103,
  Rectangle104,
  Rectangle105,
  Rectangle115,
  Rectangle116,
  Rectangle117,
  Rectangle118,
  Rectangle119,
  Rectangle120,
  Rectangle121,
  Rectangle122,
  Rectangle123,
  Rectangle124,
  Rectangle181,
  Rectangle241,
  Rectangle261,
  Rectangle262,
  Rectangle263,
  Rectangle264,
  Rectangle265,
  Rectangle266,
  Rectangle267,
  Rectangle268,
  Rectangle269,
  Rectangle270,
  Rectangle271,
  Rectangle272,
  Rectangle277,
  Rectangle299,
  Rectangle300,
  Rectangle301,
  Rectangle302,
  Rectangle308,
  Rectangle311,
  Rectangle313,
  Rectangle317,
  Rectangle318,
  Rectangle319,
  Rectangle320,
  Rectangle321,
  right,
  coin_1,
  share,
  share2,
  share3,
  share4,
  share5,
  slidshow,
  smart_display,
  simg,
  simg1,
  teamsimg1,
  treating_1,
  treating_2,
  treating_3,
  treating_4,
  trending_posts,
  twitter,
  unit_1,
  unit_2,
  Vector,
  video,
  video2,
  go,
  w_logo,
  youtube,
  Ellipse86,
  Ellipse109,
  Ellipse125,

  image3,
};
export default PublicImages;

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import PublicImages from "../../constants/public/images";
import { Row, Col } from "react-bootstrap";
import Layout from "../../layouts/public/Layout";
import { getRequest, uploadURL } from "../../helper/api";

import InfiniteScroll from "react-infinite-scroll-component";

const Challenges = () => {
  const { product } = useParams();
  const { state } = useLocation();
  const navigator = useNavigate();
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const [latestPostsPage, setLatestPostsPage] = useState(1);
  const [latestPostLoading, setLatestPostLoading] = useState(false);
  const [latestPostEnded, setLatestPostEnded] = useState(true);
  const [latestError, setLatestError] = useState("");

  const loadChallenges = async (page = 1, product) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-type`,
        "",
        {
          productSlug: product,
          page,
          limit: 10,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setLatestError("");
        if (page === 1) {
          setAllData(result?.data?.challenges);
          // Inside the function where you load more data
          setAllDataLength(result?.data?.challenges);
        } else {
          setAllData((prevData) => [...prevData, ...result?.data?.challenges]);
          // Inside the function where you load more data
          setAllDataLength(allData.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setLatestPostEnded(false);
        }
        setLatestPostsPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setLatestError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLatestPostLoading(false);
    }
  };

  useEffect(() => {
    if (
      !(
        product === "blog" ||
        product === "short-stories" ||
        product === "videos" ||
        product === "audio-station" ||
        product === "image-corner"
      )
    ) {
      navigator("/404");
    } else {
      loadChallenges(1, product);
    }
    console.log("state", state);
  }, [product, state]);
  return (
    <Layout>
      <section className="popular_sec">
        <Container>
          <h1>
            <img src={PublicImages.pages} className="img-fluid" alt="" />
            Challenges
          </h1>
          {latestPostLoading ? (
            <Loader loading={true} />
          ) : (
            <div>
              {latestError ? (
                latestError
              ) : (
                <InfiniteScroll
                  dataLength={allDataLength}
                  next={() => {
                    loadChallenges(latestPostsPage, product);
                  }}
                  inverse={false}
                  hasMore={latestPostEnded}
                  loader={<Loader loading={true} />}
                  scrollThreshold={0.9}
                  endMessage={
                    <p
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <b>Yay! You've seen it all!</b>
                    </p>
                  }
                >
                  <Row className="m-0">
                    {allData.map(
                      ({ slug, photo, name, challengeEndDate }, index) => (
                        <Col lg={3} md={6} xs={12} key={index}>
                          <Card className="card_style_2 ">
                            <Link
                              to={`/${product}/challenges/${slug}`}
                              className="text-decoration-none text-dark d-block  w-100"
                              style={{
                                cursor: "pointer",
                                position: "relative",
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                src={uploadURL + photo}
                                className="img-fluid w-100 challengeImage v2"
                                wrapperClassName="d-block"
                                alt=""
                              />
                              {challengeEndDate &&
                                moment(challengeEndDate).isSameOrAfter(
                                  moment(new Date()).format("YYYY-MM-DD")
                                ) && (
                                  <span className="badge badge-primary challenge_live">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color="#fff"
                                      className={`pe-2 fa-beat Blink`}
                                    />
                                    Live
                                  </span>
                                )}

                              <Card.Body className="text-center">
                                <Card.Title>
                                  <p>{name}</p>
                                </Card.Title>
                              </Card.Body>
                            </Link>
                          </Card>
                        </Col>
                      )
                    )}
                  </Row>
                </InfiniteScroll>
              )}
            </div>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Challenges;
